<template>
  <div class="bg-gray">
    <div class="container">
      <Back />
      <h3 class="mt-3 mb-3 display-5 fw-bold">AI Solutions</h3>

      <img src="@/assets/img/ai-solutions.png" alt="Asset" class="img-fluid" />
      <div class="konten">
        <h4 class="bt-txt-title-container mt-5 mb-4 font-medium">Accelerating AI Readiness for the Indonesian Government</h4>
        <h5 class="bt-txt-desc-container w-100 mb-5 font-normal">
          Change drives business today. To keep up, your organization needs to be able to evolve.
          Birutekno gives you the insight to drive strategy, the agility to adapt, and the efficiency to 
          achieve your goals.
        </h5>
        <div class="d-flex w-100 mb-5">
          <div class="mx-auto">
            <GetStarted />
          </div>
        </div>
      </div>
      <p class="text-center mt-3 mb-5">
        Indonesian leading companies trust birutekno.
      </p>
      <!-- partner -->
      <div class="row d-flex align-items-center text-center">
        <div class="col-md-2 mb-3 mt-3">
          <img src="@/assets/img/logo_bni.svg" alt="bni" class="mx-auto" />
        </div>
        <div class="col-md-2 mb-3 mt-3">
          <img src="@/assets/img/logo_acset.png" alt="acset" class="mx-auto" />
        </div>
        <div class="col-md-2 mb-3 mt-3">
          <img
            src="@/assets/img/logo_dapenbi.svg"
            alt="image1"
            class="mx-auto"
          />
        </div>
        <div class="col-md-2 mb-3 mt-3">
          <img src="@/assets/img/logo_ykbbi.png" alt="image2" class="mx-auto" />
        </div>
        <div class="col-md-2 mb-3 mt-3">
          <img src="@/assets/img/logo_iapi.svg" alt="iapi" class="mx-auto" />
        </div>
        <div class="col-md-2 mb-3 mt-3">
          <img src="@/assets/img/logo_bpom.svg" alt="bpom" class="mx-auto" />
        </div>
      </div>
      <!-- end partner -->
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
import GetStarted from "@/components/GetStarted.vue";
export default {
  components: {
    Back,
    GetStarted,
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>
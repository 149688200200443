<template>
  <div class="back my-3">
    <div
      class="flex align-items-center space-x-1 text-primary cursor-pointer"
      @click="$store.dispatch('back')"
    >
      <b-icon icon="arrow-left"></b-icon><span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: "Back",
      type: String,
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="container-fluid bg-white">
      <div class="container judul py-4">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <p class="text-primary mt-5 p">Sales Management</p>
              <h2 class="bt-txt-title-container mt-3 mb-3 fw-bold text-5xl leading-normal">
                Leveling Up Your Sales <br/> and Marketing
              </h2>
              <h5 class="bt-txt-desc-container mb-5 text-xl leading-normal w-75">
                Looking for a new way to have your business be more profitable? We're ready to help you grow your business reach sales objectives.
              </h5>
              <GetStarted />
            </div>
            <div class="col-md-5 d-flex align-items-center">
              <img src="@/assets/img/sales.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <!-- Companies -->
      <div class="container-fluid" style="background-color: whitesmoke">
        <div class="mt-3">
          <div class="container py-5">
            <div class="d-flex flex-column text-center">
              <p class="text-primary">
                Indonesian leading companies trust birutekno
              </p>
              <div class="d-flex justify-content-center my-2">
                <form
                  action=""
                  class="
                    flex
                    space-x-14
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="">
                    <img src="@/assets/img/acset.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_dtpeduli.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_jualaja.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_ytruck.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_cca.svg" alt="" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- AKHIR Companies -->
    </div>
    <!-- GOALS -->
    <div class="container-fluid bg-white">
      <div class="container">
        <p class="bt-txt-desc-container text-primary pt-5 pb-1">Achieve Your Goals</p>
        <h2 class="bt-txt-title-container mt-3 mb-3 fw-bold text-5xl leading-normal">
          Many ways sales can help you grow your <br /> business. 
        </h2>
        <div class="">
          <div class="row">
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/achieve1.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Build, inspire and connect with your audience.
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                We can help you to boost your business sales and generate more revenue by making great customer experience.
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img src="@/assets/img/achieve2.svg" alt="img" class="" />    
              </div>

              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Higher Productivity
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Active selling is the best way to increase your 
                sales for the amount of time you spend actively selling.
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/achieve3.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Adding Value
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Help your customer understand the value of
                your business to beat the competition.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR GOALS -->
    <div class="container-fluid bg-white">
      <div class="container">
          <div class="">
            <div class="row">
              <div class="col-lg-5">
                <div class="h-90 flex items-end mb-5">
                  <img src="@/assets/img/outcomes.png" alt="" class="img-fluid" />
                </div>
              </div>
              <div class="col-lg-7 isi">
                <p class="bt-txt-desc-container text-primary pt-5 pb-1">Outcomes</p>
                <h2 class="bt-txt-title-container mt-3 mb-3 fw-bold text-5xl leading-normal">
                  Want to get more leads?
                </h2>
                <p class="leading-loose w-11/12">
                  We can help you to boost your business sales and generate more revenue by making great customer experience.
                </p>
                <div class="d-flex">
                  <div class="row w-10/11 mx-auto">
                    <div class="col-lg-6 p-1 isi">
                      <div class="h-20">
                        <div class="h-20 flex items-end mb-4">
                          <div class="col-lg-3"><img src="@/assets/img/outcomes1.svg" alt="" /></div>
                          <div class="text-lg fw-bold">Improved customer satisfaction & loyalty</div>
                        </div>
                        <div class="h-20 flex items-end mb-4">
                          <div class="col-lg-3"><img src="@/assets/img/outcomes2.svg" alt="" /></div>
                          <div class="text-lg fw-bold">Increase sales revenue</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 p-1 isi">
                      <div class="h-30">
                        <div class="h-20 flex items-end mb-4">
                          <div class="col-lg-3"><img src="@/assets/img/outcomes3.svg" alt="" /></div>
                          <div class="text-lg fw-bold">Greater profitability</div>
                        </div>
                        <div class="h-20 flex items-end mb-4">
                          <div class="col-lg-3"><img src="@/assets/img/outcomes4.svg" alt="" /></div>
                          <div class="text-lg fw-bold">Boost organizations productivity</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- Serve -->
    <div class="bg-white py-5">
      <div class="container">
        <div class="container" id="serve">
          <div v-for="(data, i) in serve" :key="i">
            <div class="row justify-content-center my-">
              <div class="col-md-3 my-4">
                <Img :img="data.img" />
              </div>
              <div class="col-md-7 align-self-center">
                <p class="bt-txt-desc-container text-xl fw-bold">{{ data.title }}</p>
                <p class="bt-txt-desc-container w-9/12">{{ data.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LetsTalk />
  </div>
  <!-- AKHIR SERVE -->


</template>
<script>
import GetStarted from "@/components/GetStarted";
import Img from "@/components/Img";
import LetsTalk from "@/components/LetsTalk";
export default {
  components: {
    LetsTalk,
    GetStarted,
    Img,
  },
  mounted() {
    this.hrTop();
    this.hrBot();
  },
  methods: {
    hrTop() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.prepend(hr);
    },
    hrBot() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.append(hr);
    },
  },
};
</script>
<style scoped>
.p{
  text-align :left;
} 
@media only screen and (max-width: 992px) {
  h5,
  p {
    text-align: center;
    margin: 0 auto;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  img {
    margin: 0;
  }
}
.isi img {
  vertical-align: bottom;
}
#serve img {
  width: 211px;
}
</style>
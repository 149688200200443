<template>
  <div>
    <div class="container">
      <Back />
      <h1 class="mt-3 mb-3 fw-bold text-3xl">Expertise</h1>
      <p class="mb-5">
        Interested In Internship Or Full-Time Position. Let Us Know!
      </p>
    </div>
    <!-- Platform -->
    <div class="container">
      <h3 class="mt-3 mb-3 fw-bold text-2xl">Platforms</h3>
      <p class="mb-5 mt-3">
        A platform is a group of technologies that are used as a base upon which
        other applications, processes or technologies are developed.
      </p>
    </div>
    <div class="mt-3" style="background-color: #f5f5f5">
      <div class="container py-5">
        <div
          class="
            d-flex
            justify-content-between
            align-items-end
            flex-wrap
            space-x-5
          "
        >
          <div class="text-center">
            <img src="@/assets/img/jam.svg" alt="img" class="pb-2 mx-auto" />
            <p>Wearable</p>
          </div>
          <div class="text-center">
            <img src="@/assets/img/hape.svg" alt="img" class="pb-2 mx-auto" />
            <p>Phone</p>
          </div>
          <div class="text-center">
            <img src="@/assets/img/tab.svg" alt="img" class="pb-2 mx-auto" />
            <p>Tab</p>
          </div>
          <div class="text-center">
            <img src="@/assets/img/laptop.svg" alt="img" class="pb-2 mx-auto" />
            <p>Laptop</p>
          </div>
          <div class="text-center">
            <img
              src="@/assets/img/computer.svg"
              alt="img"
              class="pb-2 mx-auto"
            />
            <p>Dekstop</p>
          </div>
          <div class="text-center">
            <svg
              width="257"
              height="157"
              class="pb-2 mx-auto"
              viewBox="0 0 257 157"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M244.417 3H13C7.47715 3 3 7.47715 3 13V144.101C3 149.624 7.47715 154.101 13 154.101H244.417C249.94 154.101 254.417 149.624 254.417 144.101V13C254.417 7.47715 249.94 3 244.417 3Z"
                stroke="#535557"
                stroke-width="5"
                stroke-miterlimit="10"
              />
            </svg>
            <p>Bigger Screen</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Akhir Platform -->
    <!-- Technology -->
    <div class="container mt-5">
      <h3 class="mt-3 mb-3 fw-bold text-2xl">Technology</h3>
      <p class="mb-5 mt-3">
        A platform is a group of technologies that are used as a base upon which
        other applications, processes or technologies are developed.
      </p>
    </div>
    <div class="container">
      <div class="container-fluid d-flex justify-content-center my-5">
        <div class="row mb-5">
          <div class="col p-5 rounded-3" style="background-color: #2658a8">
            <img src="@/assets/img/teknologi.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- Akhir Technology -->
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
export default {
  components: { Back },
};
</script>

<style>
</style>
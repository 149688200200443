<template>
  <div class="bg-gray">
    <div class="container-lg pt-3">
      <Back text="Get Started" />
      <h3 class="mt-3 mb-3 text-3xl fw-bold">Consult Your Business With Us</h3>
      <!-- FORM -->
      <div class="formm">
        <div class="mb-3">
          <div class="row mb-3">
            <div class="col">
              <input
                type="text"
                class="form-control"
                id="nama"
                placeholder="Your Name"
              />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="Your Email"
              />
            </div>
          </div>
          <textarea
            class="form-control mb-3"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Project Description (briefly)"
          ></textarea>
          <div class="row mb-5">
            <div class="col">
              <input
                type="text"
                class="form-control"
                id="nama"
                placeholder="Estimated Budget (from)"
              />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="Estimated Budget (to)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- AKHIR FORM -->
      <!-- INDUSTRI -->
      <h4 class="mb-3 text-xl">Your Industries</h4>
      <div class="row mb-5">
        <div class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Education
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Finance
            </label>
          </div>
        </div>
        <div class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Manufacture
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Retail
            </label>
          </div>
        </div>
        <div class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Constraction
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Security
            </label>
          </div>
        </div>
      </div>
      <!-- AKHIR INDUSTRI -->
      <!-- Any Related Product? -->
      <h4 class="text-xl">Do you have any related products?</h4>
      <div class="row d-flex align-items-start">
        <div class="col-md-4">
          <label for="flexRadioDefault1">
          <div class="card mb-3 h-36 d-flex py-3" style="">
            <div class="form-check ms-3">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <img src="@/assets/img/vros.svg" class="" alt="..." width="80" />
              <div class="card-body px-0">
                <p class="card-text text-muted">
                  Mobile application still the best and smart way for
                  communication & Collaborations.
                </p>
              </div>
            </div>
          </div>
          </label>
        </div>
        <div class="col-md-4">
          <div class="card mb-3 h-36 d-flex py-3" style="" @click="bakatai">
            <div class="form-check ms-3">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                ref="bakatai"
                id="flexRadioDefault2"
              />
              <img
                src="@/assets/img/bakat.ai.svg"
                class=""
                alt="..."
                width="90"
              />
              <div class="card-body px-0">
                <p class="card-text text-muted w-48">
                  #1st Intelligence Hiring Platform in Indonesia
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label for="flexRadioDefault3">
          <div class="card mb-3 h-36 d-flex py-3" style="">
            <div class="form-check ms-3">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
              />
              <img src="@/assets/img/soca.svg" class="" alt="..." width="75" />
              <div class="card-body px-0">
                <p class="card-text text-muted">
                  The first platform in Indonesia that empower exam proctoring,
                  assessment & surveillance with A.I .
                </p>
              </div>
            </div>
          </div>
          </label>
        </div>
      </div>
      <!-- AKHIR Any Related Product? -->
      <!-- BUTTON SUBMIT -->
      <button type="button" class="btn bg-orange text-white mb-5">
        Submit
      </button>
      <!-- AKHIR BUTTON -->
    </div>
  </div>
</template>

<script>
import Back from "../components/Back.vue";
export default {
  components: { Back },
  methods:{
    bakatai(){ 
      this.$refs.bakatai.click()
    }
  }
};
</script>

<style>
.form-control {
  background-color: #ededed;
}
p {
  font-size: 16px;
}
</style>
<template>
  <div>
    <div class="container">
      <div class="row">
        <div
          class="col-md-4 mb-3"
          v-for="(item, i) in enterpriseProject"
          :key="i"
        >
          <div class="mb-3 h-28 flex align-items-end">
            <Img :img="item.logo" class="max-h-20" />
          </div>
          <h3 class="fw-bold">{{ item.company }}</h3>
          <p class="text--muted">{{ item.type }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Img from "@/components/Img";
export default {
  components: {
    Img,
  },
  data() {
    return {
      enterpriseProject: [
        {
          logo: "logo_dapenbi.png",
          company: "Dana Pensiun BI",
          type: "Financial & Services",
        },
        {
          logo: "logo_itb.svg",
          company: "ITB",
          type: "Educational Services",
        },
        {
          logo: "logo_ytruck.png",
          company: "Yellow Truck",
          type: "Retail Business",
        },
        {
          logo: "logo_bni.png",
          company: "Bank BNI",
          type: "Financial & Services",
        },
        {
          logo: "logo_epson.svg",
          company: "Epson",
          type: "Manufacture",
        },
        {
          logo: "logo_dusunBambu.svg",
          company: "Dusun Bambu",
          type: "Retail Business",
        },
        {
          logo: "logo_heksa.svg",
          company: "PT. Heksa",
          type: "Security & Services",
        },
        {
          logo: "logo_jualaja.svg",
          company: "Jualaja.id",
          type: "Retail Business",
        },
        {
          logo: "logo_pom.png",
          company: "Badan POM",
          type: "Manufacture & Pharmacy",
        },
        {
          logo: "logo_iapi.png",
          company: "IAPI",
          type: "Educational Services",
        },
        {
          logo: "logo_ykkbi.png",
          company: "YKKBI",
          type: "Financial & Services",
        },
        {
          logo: "logo_coca_cola.svg",
          company: "Coca-Cola Amatil",
          type: "Manufacture",
        },
      ],
    };
  },
};
</script>

<style>
@media only screen and (max-width: 992px) {
  h3,
  p {
    text-align: center;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  h3,
  p {
    text-align: left;
  }
  img {
    margin: 0;
  }
}
</style>
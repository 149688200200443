<template>
  <div>
    <div class="container">
      <Back />
      <h1 class="my-5 fw-bold display-5" id="top">Contact Us</h1>
      <div class="mb-5">
        <div class="d-flex justify-content-center">
          <img src="@/assets/img/contactUs.png" alt="" class="mb-4" />
        </div>
        <p class="bt-txt-desc-container">
          We help organizations across the private, public, and social sectors create the change that matters most to them. Let’s talk about your needs, we'd love to hear from you. Here how you can reach us!
        </p>
      </div>
      <div>
        <div class="row mb-5">
          <div class="col-md-6">
            <div class="mb-5 ">
              <h1 class="fw-bold text-2xl">Our Office</h1>
              <p class="mb-4 bt-txt-desc-container">Open from 9am to 6pm Business Hour</p>
              <h3 class="fw-bold mb-3 text-lg">Address</h3>
              <div class="mb-4 d-flex align-items-center">
                <img
                  src="@/assets/img/icon_office.svg"
                  alt=""
                  class="me-3 px-1"
                />Jl. Grand Sharon Raya Utara No. 11 Kav 282 Cipamokolan 40292
                Bandung
              </div>
              <div class="mb-4 d-flex align-items-center">
                <img
                  src="@/assets/img/icon_contact.svg"
                  alt=""
                  class="me-3"
                />(022) 8731 9210
              </div>
              <div class="mb-4 flex">
                <img
                  src="@/assets/img/icon_inbox.svg"
                  alt=""
                  class="me-3 pe-1"
                />Info@birutekno.com
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22403.708061280908!2d107.66118049382617!3d-6.955713186707421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68dd5c3b7ecca7%3A0xe96190a607afd8d4!2sBirutekno%20Inc.!5e0!3m2!1sen!2sid!4v1637208768099!5m2!1sen!2sid"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
export default {
  components: { Back },
  data() {
    return {
    };
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="container mt-5 mb-3">
      <Back />
      <h1 class="fw-bold display-5">Success Story</h1>
      <p class="bt-txt-desc-container">We love seeing clients happily using our solutions. Below are a few stories from our clients and see what we can do for you!</p>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Tejo Kusumo.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">IT Manager DAPENBI</p>
              <h5 class="card-title fw-bold text-lg">Tejo Kusumo</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Gilarsi W. Setijono.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">Direktur Utama PT.Pos Indonesia</p>
              <h5 class="card-title fw-bold text-lg">Gilarsi W. Setijono</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Son Jong Hyun.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">CEO CTS24</p>
              <h5 class="card-title fw-bold text-lg">Son Jong Hyun</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Ema Setyawati.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">Direktur Pengawasan Pangan</p>
              <h5 class="card-title fw-bold text-lg">Ema Setyawati</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Noor Cahyo.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">Pengurus YKKBI</p>
              <h5 class="card-title fw-bold text-lg">Noor Cahyo</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Arief Oesman.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">IT Manager PT.Merck Tbk.</p>
              <h5 class="card-title fw-bold text-lg">Arief Osman</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Ronny Lukito.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">Owner Dusun Bambu</p>
              <h5 class="card-title fw-bold text-lg">Ronny Lukito</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Eddy Wiryawan.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">Owner AA Jaya Group Corps</p>
              <h5 class="card-title fw-bold text-lg">Eddy Wiryawan</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Sugiono W. Sugialam.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="card-text">Owner PT.Trikomsel Oke Tbk.</p>
              <h5 class="card-title fw-bold text-lg">Sugiyono W. Sugialam</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card mb-3 mt-3 bg-color-gray">
            <img
              src="@/assets/img/Budi Wisnu.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <p class="">VP IT PT. PINDAD (Persero)</p>
              <h5 class="card-title fw-bold text-lg">Budi Wisnu</h5>
              <div class="flex space-x-2 align-items-center">
                <!-- <a href="/success-story-detail" class="text-decoration-none"
                  >What are they saying </a
                ><b-icon icon="arrow-right"></b-icon> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR STORY -->
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
export default {
  components: {
    Back,
  },
};
</script>

<style scoped>
.font-weight-500 {
  font-weight: 500;
}
.bg-color-gray {
  background-color: #e5e5e5;
}
.card {
  width: 20rem;
  height: 500px;
}
@media only screen and (min-width: 768px) {
  .card {
    width: 21rem;
    height: 500px;
  }
}
@media only screen and (min-width: 992px) {
  .card {
    width: 19rem;
    height: 500px;
  }
}
@media only screen and (min-width: 1100px) {
  .card {
    width: 22rem;
    height: 500px;
  }
}
@media only screen and (min-width: 1200px) {
  .c{
    height: 500px;
  }
  h6 {
    width: 75%;
  }
}
@media only screen and (min-width: 1400px) {
  .card {
    width: 26rem;
    height: 570px;
  }
}
</style>

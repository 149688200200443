<template>
  <div>
    <div class="container-fluid bg-white">
      <div class="container judul py-4">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <p class="text-primary mt-5 p">Learning Management</p>
              <h2 class="bt-txt-title-container mt-3 mb-3 fw-bold text-5xl leading-normal">
                Every Learner Needs a Learning System
              </h2>
              <h5 class="bt-txt-desc-container mb-5 text-xl leading-normal w-75">
                We offer a Learning Management System that is easy to use and can scale to thousands of learners.
              </h5>
              <GetStarted />
            </div>
            <div class="col-md-5 d-flex align-items-center">
              <img src="@/assets/img/solutions1.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <!-- Companies -->
      <div class="container-fluid" style="background-color: whitesmoke">
        <div class="mt-3">
          <div class="container py-5">
            <div class="d-flex flex-column text-center">
              <p class="text-primary">
                Indonesian leading companies trust birutekno
              </p>
              <div class="d-flex justify-content-center my-2">
                <form
                  action=""
                  class="
                    flex
                    space-x-14
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="">
                    <img src="@/assets/img/logo_dtpeduli.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_jualaja.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_ytruck.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_cca.svg" alt="" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- AKHIR Companies -->
    </div>
    <!-- GOALS -->
    <div class="container-fluid bg-white">
      <div class="container">
        <p class="bt-txt-desc-container text-primary pt-5 pb-1">Achieve Your Goals</p>
        <div class="">
          <div class="row">
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/goals1.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Save times and eliminate onsite cost
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Unlike face-to-face, LMS enables you to accesses training where you wants, 
                when you wants.
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img src="@/assets/img/goals2.svg" alt="img" class="" />    
              </div>

              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Easy to track, assess, and report
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Help monitor and assess student progress quickly 
                and efficiently so that learning objectives are 
                easily achieved.
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/learning.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Get learning anywhere anytime
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                You ll still be able to access all 
                the course materials you need with 
                just one login, from anywhere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR GOALS -->
    <!-- Conditions -->
    <div class="container py-4 my-5">
      <div class="container">
        <div class="row">
        <div class="text-center">
          <div class="col mt-3">
                <h1 class="bt-txt-title-container font-bold mb-5">
                    Manage, track, and achieve<br> your
                    learning goals
                </h1>
            <p
              class="bt-txt-desc-container mb-3"
            >
              We help you to organize your learning, 
              track progress, achieve and more by <br />
               just 
              logging onto laptops, tablets or any other 
              mobile device at any time.
            </p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR Conditions -->
    <!-- Serve -->
    <div class="bg-white py-5">
      <div class="container">
        <div class="text-center">
          <p class="bt-txt-desc-container text-primary">How We Serve You</p>
          <h1 class="bt-txt-title-container font-bold mb-5">
            Easy, Flexible and Powerful Learning <br />
            Platform for Every Stage of Life
          </h1>
        </div>
        <div class="container" id="serve">
          <div v-for="(data, i) in serve" :key="i">
            <div class="row justify-content-center my-">
              <div class="col-md-3 my-4">
                <Img :img="data.img" />
              </div>
              <div class="col-md-7 align-self-center">
                <p class="bt-txt-desc-container text-xl fw-bold">{{ data.title }}</p>
                <p class="bt-txt-desc-container w-9/12">{{ data.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LetsTalk />
  </div>
  <!-- AKHIR SERVE -->

</template>
<script>
import GetStarted from "@/components/GetStarted";
import Img from "@/components/Img";
import LetsTalk from "@/components/LetsTalk";
export default {
  components: {
    LetsTalk,
    GetStarted,
    Img,
  },
  mounted() {
    this.hrTop();
    this.hrBot();
  },
  methods: {
    hrTop() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.prepend(hr);
    },
    hrBot() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.append(hr);
    },
  },
  data() {
    return {
      serve: [
        {
          img: "Rectangle1.png",
          title: '"All-in-One" tool that allows you to track productivity virtually anywhere',
          subtitle:
            "Keep track every learning activity easily for each subject matter provided by the lecturer.",
        },
        {
          img: "Rectangle3.png",
          title: "Transform your experience",
          subtitle:
            "We have carefully designed the interface so that you can learn at your own pace and never get bored.",
        },
        {
          img: "Rectangle2.png",
          title: "Digitize your course",
          subtitle:
            "We provide a comprehensive learning platform for schools, colleges and universities to easily manage content, automate tasks, and materials effectively from a single platform.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.p{
  text-align :left;
} 
@media only screen and (max-width: 992px) {
  h5,
  p {
    text-align: center;
    margin: 0 auto;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  img {
    margin: 0;
  }
}
.isi img {
  vertical-align: bottom;
}
#serve img {
  width: 211px;
}
</style>
<template>
  <div>
    <button
      class="bg-primary py-2 w-24"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBackdrop"
      aria-controls="offcanvasWithBackdrop"
    >
      Enable backdrop (default)
    </button>
    <div
      class="offcanvas offcanvas-end w-full"
      tabindex="-1"
      id="offcanvasWithBackdrop"
      aria-labelledby="offcanvasWithBackdropLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">
          Offcanvas with backdrop
        </h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="accordion navlinkmobile flex-col" id="accordionExample">
          <!-- accordion about -->
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button px-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                About
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div
                  v-for="(item, i) in subMenuAbout"
                  :key="i"
                  class="
                    flex
                    space-x-4
                    border-2 border-blue-500
                    rounded-lg
                    mb-3
                    p-2
                  "
                  @click="
                    closeSubmenuAbout();
                    $router.push({ name: item.link });
                  "
                >
                  <Img :img="item.img" />
                  <div>{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Akhir accordion About -->

          <!-- accordion solutions -->
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button px-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Solutions
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div
                  v-for="(item, i) in subMenuSolutions"
                  :key="i"
                  class="
                    flex
                    space-x-4
                    border-2 border-blue-500
                    rounded-lg
                    mb-3
                    p-2
                  "
                  @click="
                    closeSubmenuSolutions();
                    $router.push(`${item.link}`);
                  "
                >
                  <Img :img="item.img" />
                  <div>{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Img from "@/components/Img";
export default {
  components: { Img },
  data() {
    return {
      subMenuAbout: [
        {
          img: "profile.svg",
          title: "Our Profile",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "Profile",
        },
        {
          img: "about-support.svg",
          title: "Support",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "Support",
        },
        {
          img: "success-story.svg",
          title: "Success Story",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "Success-story",
        },
        {
          img: "contact.svg",
          title: "Contact",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "Contact",
        },
      ],
      subMenuSolutions: [
        {
          img: "icon-1.svg",
          title: "Human Capital Management",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "/hcm",
        },
        {
          img: "icon-2.svg",
          title: "Payroll Management",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "/payroll-management",
        },
        {
          img: "icon-4.svg",
          title: "Project Management",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "/project-management",
        },
        {
          img: "icon-4.svg",
          title: "Learning Management",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "/learning-management",
        },
        {
          img: "icon-4.svg",
          title: "Sales Management",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "/sales-management",
        },
        {
          img: "icon-4.svg",
          title: "Proctoring AI",
          subtitle: "Lorem ipsum dolor sit amet.",
          link: "/proctoring-ai",
        },
        // {
        //   img: "icon-3.svg",
        //   title: "Facial Recognition",
        //   subtitle: "Lorem ipsum dolor sit amet.",
        //   link: "/",
        // },
        // {
        //   img: "icon-4.svg",
        //   title: "Object Detection",
        //   subtitle: "Lorem ipsum dolor sit amet.",
        //   link: "/",
        // },
        // {
        //   img: "icon-4.svg",
        //   title: "Line Counter",
        //   subtitle: "Lorem ipsum dolor sit amet.",
        //   link: "/",
        // },
      ],
    };
  },
  methods: {},
};
</script>

<style>
</style>
<template>
  <div>
    <!-- CAREER -->
    <div class="container mb-5">
      <Back />
      <h1 class="fw-bold display-5">Career</h1>
      <p class="bt-txt-desc-container my-3 mb-5">
        Interested In Internship Or Full-Time Position. Let Us Know!
      </p>
      <div class="row mb-5">
        <div
          :class="'col-md-4 p-5 ' + item.bg"
          v-for="(item, i) in careerIcon"
          :key="i"
          style="height:225px;"
        >
          <div style="height: 85px;" class="flex items-end mb-3">
            <Img :img="item.icon" class="" />
          </div>
          <div>
            <h5 class="fw-bold text-lg">{{ item.text }}</h5>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR CAREER -->

    <!-- BENEFITS -->
    <div class="container py-3">
      <h3 class="my-3 fw-bold text-2xl">Benefits</h3>
      <div class="">
        <div class="row">
          <div class="col-lg-4 p-4 isi">
            <div class="h-24 flex align-items-end mb-4">
              <img src="@/assets/img/watch.svg" alt="img" class="" />
            </div>
            <h5 class="fw-bold text-xl">Flexible Hours</h5>
            <p class="">
              Adjust Your plan to <br />
              work efficiently.
            </p>
          </div>
          <div class="col-lg-4 p-4 isi">
            <div class="h-24 flex align-items-end mb-4">
              <img src="@/assets/img/party.svg" alt="img" class="" />
            </div>
            <h5 class="fw-bold text-xl">Parties</h5>
            <p class="">
              We got out regurarly <br />
              every quarter
            </p>
          </div>
          <div class="col-lg-4 p-4 isi">
            <div class="h-24 flex align-items-end mb-4">
              <img src="@/assets/img/gaming.svg" alt="img" class="" />
            </div>
            <h5 class="fw-bold text-xl">Gaming</h5>
            <p class="">
              You like playing PS4? <br />
              we have a companion!
            </p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div class="col-lg-4 p-4 isi">
            <div class="h-24 flex align-items-end mb-4">
              <img src="@/assets/img/sport.svg" alt="img" class="" />
            </div>
            <h5 class="fw-bold text-xl">Sport Activity</h5>
            <p class="">
              ABadminton, Soccer,<br />
              Chess, etc.
            </p>
          </div>
          <div class="col-lg-4 p-4 isi">
            <div class="h-24 flex align-items-end mb-4">
              <img src="@/assets/img/travel.svg" alt="img" class="" />
            </div>
            <h5 class="fw-bold text-xl">Travel</h5>
            <p class="">
              Birutekno travels a lot, <br />
              especially aroad.
            </p>
          </div>
          <div class="col-lg-4 p-4 isi">
            <div class="h-24 flex align-items-end mb-4">
              <img src="@/assets/img/hi.svg" alt="img" class="" />
            </div>
            <h5 class="fw-bold text-xl">Health Insurance</h5>
            <p class="">
              Health and life <br />
              insurance
            </p>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btn bg-orange text-white mb-5 mt-2 px-4 py-2"
        >
          Send Your CV Now
        </button>
      </div>
    </div>
    <!-- AKHIR BENEFITS -->
  </div>
</template>

<script>
import Img from "@/components/Img";
import Back from "@/components/Back.vue";
export default {
  components: {
    Back,
    Img
  },
  data(){
    return{
      careerIcon:[
      {
        icon:"android.svg",
        text:"Android Developer",
        bg:"blue-1"
      },
      {
        icon:"analis.svg",
        text:"System Analys",
        bg:"blue-2"
      },
      {
        icon:"coding.svg",
        text:"Web Developer",
        bg:"blue-3"
      },
            {
        icon:"ios.svg",
        text:"IOS Developer",
        bg:"blue-6"
      },
            {
        icon:"uiux.svg",
        text:"UI/UX Designer",
        bg:"blue-5"
      },
      {
        icon:"gd.svg",
        text:"Graphic Designer",
        bg:"blue-4"
      }
    ]
    }
    
  }
};
</script>

<style scoped>
@media only screen and (max-width: 992px) {
  h5,
  p {
    text-align: center;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  h5,
  p {
    text-align: left;
  }
  img {
    margin: 0;
  }
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
</style>

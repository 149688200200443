<template>
  <div>
    <div class="container">
      <Back />
      <h3 class="mt-3 mb-3 fw-bold display-5">Education Solutions</h3>

      <img src="@/assets/img/education-solutions.png" alt="Asset" class="img-fluid" />
      <div class="konten">
        <h4 class="bt-txt-title-container mt-5 mb-4 font-medium">Focus on student success</h4>
        <h5 class="bt-txt-desc-container w-100 mb-5 font-normal">
          Spend less time chasing down data and relying on manual processes, and
          spend more time making the decisions that will help your students and
          institutions succeed. With birutekno,you can do just that.
        </h5>
        <div class="d-flex w-100 mb-5">
          <div class="mx-auto">
            <GetStarted />
          </div>
        </div>
      </div>
      <p class="text-center mt-3 mb-5 text-lg">
        Indonesian leading companies trust birutekno.
      </p>
      <!-- partner -->
      <div class="row d-flex align-items-center text-center mb-4">
        <div class="col-md-4 mb-3 mt-3">
          <img src="@/assets/img/logo_kp3skp.png" alt="bni" class="mx-auto" />
        </div>
        <div class="col-md-4 mb-3 mt-3">
          <img src="@/assets/img/logo_iapi.png" alt="acset" class="mx-auto" />
        </div>
        <div class="col-md-4 mb-3 mt-3">
          <img src="@/assets/img/logo_pom.png" alt="image1" class="mx-auto" />
        </div>
      </div>
    </div>
    <!-- end partner -->
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
import GetStarted from "@/components/GetStarted.vue";
export default {
  components: {
    Back,
    GetStarted
  },
  data() {
    return {};
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="container-fluid bg-white">
      <div class="container judul py-4">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p class="text-primary mt-5 p">Human Capital Management</p>
              <h2 class="mt-3 mb-3 fw-bold text-5xl leading-normal">
                Easy, automated solutions to simplify your HR & IT
              </h2>
              <h5 class="mb-5 text-xl leading-normal">
                The all-in-one platform that makes it easily to manage multiple workflows and collaborate with your team efficiently
              </h5>
              <GetStarted />
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <img src="@/assets/img/hcm.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <!-- Companies -->
      <div class="container-fluid" style="background-color: whitesmoke">
        <div class="mt-3">
          <div class="container py-5">
            <div class="d-flex flex-column text-center">
              <p class="text-primary">
                Indonesian leading companies trust birutekno
              </p>
              <div class="d-flex justify-content-center my-2">
                <form
                  action=""
                  class="
                    flex
                    space-x-14
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="">
                    <img src="@/assets/img/logo_bni.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/acset.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_dapenbi.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_ykkbi.png" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_iapi.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_bpom.svg" alt="" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- AKHIR Companies -->
    </div>
    <!-- GOALS -->
    <div class="container-fluid bg-white">
      <div class="container">
        <p class="text-primary py-5">Achieve Your Goals</p>
        <div class="">
          <div class="row">
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/automate_hr.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Automate HR processes easily
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Easily automate the core HR process, reducing unnecessary management and paperwork.
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img src="@/assets/img/powering_people.svg" alt="img" class="" />    
              </div>

              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Powering People Operations
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Maximize HR productivity, minimize errors, and spend more time delivering value
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/strategy_hr.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Easily set up the new strategy of HR
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                By understanding what employees need, how they work, and what motivates them, you can put people at the heart of your HR strategy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR GOALS -->
    <div class="container py-4 my-5">
      <div class="container">
        <div class="row">
        <div class="text-center">
          <div class="col mt-3">
                <h1 class="bt-txt-title-container font-bold mb-5">
                    Ready for a smarter way to handle HR, payroll and benefits?
                </h1>
            <p
              class="bt-txt-desc-container mb-3"
            >
              With our software you can automatically connecting HR, Benefits, Payroll and Scheduling,<br>together in one platform with one easy-to-use software. 
            </p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Conditions -->
    <!-- AKHIR Conditions -->
    <!-- Serve -->
    <div class="bg-white py-5">
      <div class="container">
        <div class="text-center">
          <p class="text-primary">How We Serve You</p>
          <h1 class="font-bold mb-5">
            Manage your people<br>without the headache
          </h1>
        </div>
        <div class="container" id="serve">
          <div v-for="(data, i) in serve" :key="i">
            <div class="row justify-content-center my-">
              <div class="col-md-3 my-4">
                <Img :img="data.img" />
              </div>
              <div class="col-md-6 align-self-center">
                <p class="text-xl fw-bold">{{ data.title }}</p>
                <p class="w-9/12">{{ data.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LetsTalk />
  </div>
  <!-- AKHIR SERVE -->
</template>
<script>
import GetStarted from "@/components/GetStarted";
import Img from "@/components/Img";
import LetsTalk from "@/components/LetsTalk";
export default {
  components: {
    LetsTalk,
    GetStarted,
    Img,
  },
  mounted() {
    this.hrTop();
    this.hrBot();
  },
  methods: {
    hrTop() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.prepend(hr);
    },
    hrBot() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.append(hr);
    },
  },
  data() {
    return {
      serve: [
        {
          img: "hcm_1.png",
          title: "Bring Together Financial and HR Data",
          subtitle:
            "Helps to manage numerous tasks of HR including payroll, financial, workforce management and transform data to act on real-time business needs.",
        },
        {
          img: "hcm_2.png",
          title: "Track time & attendance",
          subtitle:
            "Easily access statistics to record and analyze timesheets as well as check each employee's attendance. ",
        },
        {
          img: "hcm_3.png",
          title: "Manage It All in One Place",
          subtitle:
            "An all-in-one HR technology suite that offers a great employee experience plus the flexibility and scalability to adapt to your evolving needs.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.p{
  text-align :left;
} 
@media only screen and (max-width: 992px) {
  h5,
  p {
    text-align: center;
    margin: 0 auto;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  img {
    margin: 0;
  }
}
.isi img {
  vertical-align: bottom;
}
#serve img {
  width: 211px;
}
</style>
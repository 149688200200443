<template>
  <div>
    <div class="container-fluid bg-white">
      <div class="container judul py-4">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <p class="bt-txt-title-container text-primary mt-5 p">Proctoring AI</p>
              <h2 class="bt-txt-desc-container mt-3 mb-3 fw-bold text-5xl leading-normal">
                Online Exam with <br />
                Artificial Intelligence
              </h2>
              <h5 class="bt-txt-desc-container mb-5 text-xl leading-normal">
                Powering online exam by proctoring A.I with <br />
                highly security to achieve better integrity.
              </h5>
              <GetStarted />
            </div>
            <div class="col-md-5 d-flex align-items-center">
              <img src="@/assets/img/AI.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <!-- Companies -->
      <div class="container-fluid" style="background-color: whitesmoke">
        <div class="mt-3">
          <div class="container py-5">
            <div class="d-flex flex-column text-center">
              <p class="text-primary">
                Indonesian leading companies trust birutekno
              </p>
              <div class="d-flex justify-content-center my-2">
                <form
                  action=""
                  class="
                    flex
                    space-x-14
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="">
                    <img src="@/assets/img/acset.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_dtpeduli.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_jualaja.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_ytruck.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_cca.svg" alt="" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- AKHIR Companies -->
    </div>
    <!-- Offering Services -->
    <div class="container-fluid bg-white">
      <div class="container">
        <div class="text-center">
          <div class="col mt-3">
            <p class="text-primary py-5">Offering Services</p>
            <h1 class="bt-txt-title-container font-bold mb-3">
              Collaborate to Accelerate
            </h1>
            <p class="bt-txt-desc-container mb-5">
              In the future we're going to collaborate with a machine<br>not just human. This is the best way to accelerate your business.
            </p>
          </div>
        </div>
        <div class="d-flex">
          <div class="row w-10/12 mx-auto">
            <div class="col-lg-6 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/biometrics.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-10">
                <h5 class="fw-bold text-xl mb-1">
                  Biometrics Authentication
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Secure login with face recognition to avoid fraud or 
                "joki" with 99% of accuracy our anti-spoofing technology 
                will prevent fraud for early access in the pre-exam process.
              </p>
            </div>
            <div class="col-lg-6 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/real.png"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-10">
                <h5 class="fw-bold text-xl mb-1">
                  Real-Time Monitoring 
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                During the exam, the instructor will be 
                notified, in real time, with any suspicious 
                activity by the exam takers. The instructor 
                can evaluate Trust Score & Fraud Score if they 
                found bias from AI through our dashboard. 
              </p>
            </div>
          </div>
        </div>
        <div class="row w-10/12 mx-auto">
            <div class="col-lg-6 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/activity.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-10">
                <h5 class="fw-bold text-xl mb-1">
                  Activity Protection
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Blocked any applications and activity during exam. 
                Exam takers even cannot use and connect to external or mirroring screen. 
                By applying this technology we can prevent their potential of cheating.
              </p>
            </div>
            <div class="col-lg-6 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/analytics.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-10">
                <h5 class="fw-bold text-xl mb-1">
                  Analytics Dashboard
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Data-driven is one of the important thing before institution or instructor  
                giving test scores to candidates. Insight from proctoring dashboard 
                will be more convincing before graduating.
              </p>
            </div>
          </div>
      </div>
    </div>
    <!-- AKHIR Offering Services -->
    <!-- Benefits -->
    <div class="container-fluid bg-white">
      <div class="container">
        <p class="bt-txt-desc-container text-primary pt-5 pb-1">Benefits</p>
          <h1 class="bt-txt-title-container font-bold mb-3">  
            Why Don't We Have Proctoring AI Yet?
          </h1>
          <p class="bt-txt-desc-container mb-5">
            When you proctor your exams with Proctoring AI, they can be taken remotely! Proctoring AI provides <br />
            several benefits over of a paper-based one.
          </p>
          <div class="">
            <div class="row">
              <div class="col-lg-5">
                <div class="h-10 flex items-end mb-5">
                  <div class="col-lg-2"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Increase security</div>
                </div>
                <div class="h-10 flex items-end mb-5 ">
                  <div class="col-lg-2  isi"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Cost and time-effective</div>
                </div>
                <div class="h-10 flex items-end mb-5">
                  <div class="col-lg-2  isi"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Objective assessment</div>
                </div>
                <div class="h-10 flex items-end mb-5">
                  <div class="col-lg-2  isi"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Maintain academic integrity</div>
                </div>
              </div>
              <div class="col-lg-5 isi">
                <div class="h-10 flex items-end mb-5">
                  <div class="col-lg-2  isi"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Detect cheating behavior</div>
                </div>
                <div class="h-10 flex items-end mb-5">
                  <div class="col-lg-2 isi"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Detect identity fraud</div>
                </div>
                <div class="h-10 flex items-end mb-5">
                  <div class="col-lg-2  isi"><img src="@/assets/img/point.svg" alt="" /></div>
                  <div class="text-lg">Identifying any suspicious behavior</div>
                </div>
                <!--Akhir Benefits -->
              </div>
              <!--Solutions -->
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <p class="bt-txt-title-container text-primary mt-20">Solutions</p>
                  <h3 class="bt-txt-title-container mt-3 mb-3 fw-bold text-5xl leading-normal">
                    We are proud to bring you our solution
                    </h3>
                  <p class="bt-txt-desc-container mb-20 me-2 w-10/12">
                    We aim to contribute in the efforts to preserve integrity 
                    for the better by developing an AI solution across a wide 
                    range of sectors based on your needs.
                  </p>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <img src="@/assets/img/solusi.svg" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR Solutions -->
    <!-- Serve -->
    <div class="bg-white py-5">
      <div class="container">
        <div class="container" id="serve">
          <div v-for="(data, i) in serve" :key="i">
            <div class="row justify-content-center my-">
              <div class="col-md-3 my-4">
                <Img :img="data.img" />
              </div>
              <div class="col-md-6 align-self-center">
                <p class="text-xl fw-bold">{{ data.title }}</p>
                <p class="w-9/12">{{ data.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LetsTalk />
  </div>
  
  <!-- AKHIR SERVE -->
  
</template>
<script>
import GetStarted from "@/components/GetStarted";
import Img from "@/components/Img";
import LetsTalk from "@/components/LetsTalk";
export default {
  components: {
    LetsTalk,
    GetStarted,
    Img,
  },
  mounted() {
    this.hrTop();
    this.hrBot();
  },
  methods: {
    hrTop() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.prepend(hr);
    },
    hrBot() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.append(hr);
    },
  },
  
};
</script>
<style scoped>
.p{
  text-align :left;
} 
@media only screen and (max-width: 992px) {
  h5,
  p {
    text-align: center;
    margin: 0 auto;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  img {
    margin: 0;
  }
}
.isi img {
  vertical-align: bottom;
}
#serve img {
  width: 211px;
}
</style>
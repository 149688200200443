<style>
    .ai-iframe-company-profile {
        width: 100vw;
        height: 100vh;
    }

    .ai-iframe-company-profile iframe {
        width:100%;
        height:100%
    }
</style>

<template>
    <div>
        <iframe
            src="https://online.fliphtml5.com/dvnuy/gzed/"
            seamless="seamless"
            scrolling="no"
            frameborder="0"
            allowtransparency="true"
            allowfullscreen="true">
        </iframe>
    </div>
</template>

<script>
    export default {
        layout: "empty"
    };
</script>

<style scoped>
    iframe {
        width: 100%;
        height: calc(100vh - 72px);
    }
</style>
<template>
    <div class="bg-lets-talk text-white py-5">
        <div class="container">

            <div class="row py-4">
                <div class="col d-flex">
                    <div class="mx-auto text-center">
                        <h1 class="bt-txt-title-container font-bold mb-4">Explore our solutions for learning management</h1>
                        <p class="bt-desc-lets-talk fw-medium mb-5">
                            See how our LMS can help support student growth and make the<br>process faster and easier to ensure learning continuity.
                        </p>
                        <div class="d-flex w-100">
                            <div
                                class="
                                    mx-auto
                                    bg-orange
                                    rounded-xl
                                    w-32
                                    xl:w-52 xl:m-0 xl:text-xl
                                    py-2 py-md-3
                                    text-center
                                ">
                                <a href="https://api.whatsapp.com/message/AKPG47WJ2UGDN1?autoload=1&app_absent=0" target="_blank" class="text-decoration-none text-white fw-bold bt-button-lets-talk">
                                    Let’s talk!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style>
    .bg-lets-talk {
        background: linear-gradient(180deg, rgba(26,70,140,1) 0%, rgba(45,99,186,1) 100%);
    }

    .bt-desc-lets-talk {
        line-height: 1.75;
        letter-spacing: 1px;
    }

    .bt-button-lets-talk {
        letter-spacing: 1px;
    }
</style>

<template>
  <div>
    <div class="container-lg">
      <Back />
      <!-- Enterprise Product -->
      <h1 class="my-3 display-5 fw-bold">Product</h1>
      <p class="bt-txt-desc-container mb-5">
        We provide a wide range of high products and services with effective and efficient solutions as a form of our commitment to push the development of technology in Indonesia. Let’s take a look!
      </p>
      <div class="mb-5">
        <h2 class="my-3 fw-bold text-2xl">Enterprise Product</h2>
        <p class="bt-txt-desc-container mt-4 mb-4">
          The technology which will continue to grow must be accompanied by the
          readiness of users. And now many companies are using the power of
          technology to operate their business.
        </p>
        <p class="bt-txt-desc-container mt-4 mb-4">
          Every company has different needs in using a technology. It because the
          flow of each business was different. And that is the excellence of
          Birutekno Inc.
        </p>
        <p class="bt-txt-desc-container mt-4 mb-4">
          We can create every solution for your business by technology. We develop
          every custom application which client want.
        </p>
      </div>
      <div class="container-lg">
        <div class="">
          <div class="row mb-5">
            <div class="col">
              <div class="h-28">
                <img src="@/assets/img/Cashless.svg" alt="img" class="pb-3" />
              </div>
              <h5 class="fw-bold text-xl mb-3">Cashless Solutions</h5>
              <p class="bt-txt-desc-product w-75">
                The new technology of payment methode. Used by Restaurant or
                Food Court which support of Cashier Application, E-money
                payment, kitchen module and management system of the restaurant
              </p>
            </div>
            <div class="col">
              <div class="h-28">
                <img src="@/assets/img/IOT.svg" alt="img" class="pb-3" />
              </div>
              <h5 class="fw-bold text-xl mb-3">IoT (Internet of Things)</h5>
              <p class="bt-txt-desc-product w-75">
                It also called Smart Home Application. Why? Because with this
                technology, you can control all your device just by a
                smartphone. Like a remote control, your smartphone can controll
                all your device activities.
              </p>
            </div>
          </div>
        </div>
        <div class="">
          <div class="row mb-5">
            <div class="col">
              <div class="h-28">
                <img
                  src="@/assets/img/SocialMediaMonitoring.svg"
                  alt="img"
                  class="pb-3"
                />
              </div>
              <h5 class="fw-bold text-xl mb-3">Social Media Monitoring</h5>
              <p class="bt-txt-desc-product w-75">
                An application that can monitor all social media accounts with
                ease. With this app you can monitor multiple accounts like FB,
                Twitter, Youtube, Instagram simultaneously
              </p>
            </div>
            <div class="col">
              <div class="h-28">
                <img
                  src="@/assets/img/MobileApplication.svg"
                  alt="img"
                  class="pb-3"
                />
              </div>
              <h5 class="fw-bold text-xl mb-3">Mobile Application</h5>
              <p class="bt-txt-desc-product w-75">
                The delivery of electronic commerce capabilities directly into
                the consumer’s hand, anywhere, via smartphone.because
                smartphones make it easy to do things
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
export default {
  components: { Back },
};
</script>
<style>
button {
  border-radius: 10px;
}
</style>
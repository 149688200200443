<template>
  <div>
    <div class="container">
      <Back />
      <h1 class="mt-3 mb-3 fw-bold display-5">Support</h1>
      <p class="bt-txt-desc-container mb-2">Do you have questions or trouble about our services?</p>
      <p class="bt-txt-desc-container mb-5">
        We are ready to help you find solutions from various types of assistance
        related to our services.
      </p>
    </div>
    <!-- SUPPORT -->
    <div class="container">
      <p class="bt-txt-desc-container mb-4">Please input your problem ticket form</p>
      <div>
        <div class="row mb-3">
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              id="nama"
              placeholder="Ticket ID"
            />
          </div>
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Name"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              id="nama"
              placeholder="Email"
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Company Name"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="input-group mb-3">
              <select class="form-select" id="inputGroupSelect01">
                <option hidden>Category</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group mb-3">
              <select
                class="form-select"
                id="inputGroupSelect01"
                aria-placeholder="choose"
              >
                <option hidden>Priority</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <input
              type="text"
              class="form-control mb-3"
              id="nama"
              placeholder="Subject"
            />
            <input
              type="text"
              class="form-control"
              id="nama"
              placeholder="Attachment File"
            />
          </div>
          <div class="col-6">
            <textarea
              class="form-control h-full mb-3"
              id="exampleFormControlTextarea1"
              placeholder="Description"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col mb-5">
            <button
              type="button"
              class="btn bg-orange text-white mb-5"
              style="width: 100px"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Akhir SUPPORT -->
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
export default {
  components: { Back },
};
</script>

<style></style>

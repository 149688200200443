<template>
  <div>
    <div class="container">
      <Back />
      <h1 class="my-3 text-3xl fw-bold">jobs</h1>
      <p class="text-muted mb-5">
        Interested In Internship Or Full-Time Position. Let Us Know!
      </p>
      <!-- atas -->
      <div class="row">
        <div class="col-md-3">
          <div style="height: 100px">
            <img src="@/assets/img/mobile.svg" />
          </div>
          <div style="width: 164x">
            <h5 class="text-lg fw-bold">
              Mobile <br />
              Development
            </h5>
          </div>
        </div>
        <div class="col-md-3">
          <div style="height: 100px">
            <img src="@/assets/img/coding.svg" />
          </div>
          <div style="width: 164x">
            <h5 class="text-lg fw-bold">
              Software <br />
              Development
            </h5>
          </div>
        </div>
        <div class="col-md-3">
          <div style="height: 100px">
            <img src="@/assets/img/multimedia.svg" />
          </div>
          <div style="width: 164x">
            <h5 class="text-lg fw-bold">
              Multimedia <br />
              Entertaiment
            </h5>
          </div>
        </div>
        <div class="col-md-3">
          <div style="height: 100px">
            <img src="@/assets/img/lamp.svg" />
          </div>
          <div style="width: 164x">
            <h5 class="text-lg fw-bold">
              Online <br />
              Sollutions
            </h5>
          </div>
        </div>
      </div>
      <!-- atas -->
      <!-- WHAT WE DO -->
      <h1 class="my-5 text-2xl fw-bold">What We Do ?</h1>
      <div class="row mb-5">
        <div
          :class="'col-md-4 p-5 ' + item.bg"
          v-for="(item, i) in whatWeDo"
          :key="i"
          style="height: 225px"
        >
          <div style="height: 85px" class="flex items-end mb-3">
            <Img :img="item.icon" class="" />
          </div>
          <div>
            <h5 class="fw-bold text-lg">{{ item.text }}</h5>
          </div>
        </div>
      </div>
      <!-- WHAT WE DO -->
    </div>
    <!-- How Us At Work -->
    <div class="position-relative mb-5">
      <div class="bg-core">
        <div class="container">
          <div class="row py-3">
            <h1 class="my-5 text-2xl fw-bold">How Us At Work</h1>
            <div class="col-md-3 mb-3">
              <div style="height: 85px" class="flex items-end mb-3">
                <img src="@/assets/img/listen.svg" />
              </div>
              <div style="width: 166px">
                <h5 class="text-lg fw-bold">
                  Listen to <br />
                  your needs
                </h5>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div style="height: 85px" class="flex items-end mb-3">
                <img src="@/assets/img/analis-problem.svg" />
              </div>
              <div style="width: 166px">
                <h5 class="text-lg fw-bold">
                  Analyze <br />
                  the problem
                </h5>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div style="height: 85px" class="flex items-end mb-3">
                <img src="@/assets/img/set.svg" />
              </div>
              <div style="width: 166px">
                <h5 class="text-lg fw-bold">
                  Set <br />
                  the times
                </h5>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <div style="height: 85px" class="flex items-end mb-3">
                <img src="@/assets/img/delivery.svg" />
              </div>
              <div style="width: 166px">
                <h5 class="text-lg fw-bold">
                  Delivery <br />
                  Solution
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- How Us At Work -->
    <!-- Benefit -->
    <div class="container benefit">
      <ul class="d-flex flex-column flex-wrap" style="height: 300px">
        <li
          v-for="(item, i) in benefit"
          :key="i"
          class="mb-3 d-flex align-items-center"
        >
          <Img img="list.svg" class="me-3" /><span class="text-lg font-medium">
            {{ item }}</span
          >
        </li>
      </ul>
    </div>
    <!-- Benefit -->
  </div>
</template>

<script>
import Img from "@/components/Img";
import Back from "@/components/Back.vue";
export default {
  components: {
    Img,
    Back,
  },
  data() {
    return {
      iconAtas: [
        {
          icon: "mobile.svg",
          text: "Mobile Development",
        },
        {
          icon: "coding.svg",
          text: "Software Development",
        },
        {
          icon: "multimedia.svg",
          text: "Multimedia Entertaiment",
        },
        {
          icon: "lamp.svg",
          text: "Online Sollutions",
        },
      ],
      whatWeDo: [
        {
          icon: "software.svg",
          text: "Software Engineering",
          bg: "blue-1",
        },
        {
          icon: "Cashless.svg",
          text: "Financial Technology",
          bg: "blue-2",
        },
        {
          icon: "uiux.svg",
          text: "UI & UX Design",
          bg: "blue-3",
        },
        {
          icon: "IOT.svg",
          text: "Internet of Things",
          bg: "blue-6",
        },
        {
          icon: "lamp.svg",
          text: "Online Solutions",
          bg: "blue-5",
        },
        {
          icon: "multimedia.svg",
          text: "Digital Solutions",
          bg: "blue-4",
        },
      ],
      howUsAtWork: [
        {
          icon: "listen.svg",
          text: "Listen to your needs",
        },
        {
          icon: "analis-problem.svg",
          text: "Analyze the problem",
        },
        {
          icon: "set.svg",
          text: "Set the times",
        },
        {
          icon: "delivery.svg",
          text: "Delivery Solution",
        },
      ],
      benefit: [
        "Free Unlimited Consulting",
        "Build Your Apps From Scratch",
        "High Level Security",
        "Always Using Lates Technology",
        "Solid Team",
        "Reasonable Price",
        "Solve Complicated Problem",
        "Flexible Bid",
        "Available Anywhere Anytime",
      ],
    };
  },
};
</script>

<style>
.howUsAtWork:before {
  background: url("../.././assets/img/howUsAtWork.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
}
.howUsAtWork::after {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.8;
}
.bg-core::before {
  content: "";
  background-image: url("../.././assets/img/howUsAtWork.png");
  background-size: cover;
  width: 100%;
  right: 0;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}
.bg-core::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.6;
  z-index: -1;
}
/* benefit */
</style>
<template>
  <div class="containerKu">
    <Back />
    <h1 class="my-3 display-5 fw-bold">Our Portfolio</h1>
    <div class="mt-5 mb-32">
      <div class="row flexRowAtas">
        <div class="col-7 flexCol d-flex justify-content-between flex-column">
          <div
            class="
              bg-white
              rounded-lg
              text-center
              py-8
              px-6
              mb-1
              align-items-center
              d-flex
              flex-column
            "
          >
            <h5 class="fw-bold lh-base">Construction<br />Management System</h5>
            <img
              src="@/assets/img/portfolio-construction.svg"
              alt="Asset"
              class="mt-8"
            />
          </div>

          <div
            class="
              bg-white
              rounded-lg
              text-center
              py-8
              px-6
              mt-2
              align-items-center
              d-flex
              flex-column
            "
          >
            <h5 class="fw-bold lh-base">
              Digital Interview, Sales Force <br />and HR Platform
            </h5>
            <img src="@/assets/img/portfolio-hr.svg" alt="Asset" class="mt-8" />
            <div class="row mw-100 pt-10">
              <img
                src="@/assets/img/portfolio-hr1.svg"
                alt="Asset"
                class="col-4"
              />
              <img
                src="@/assets/img/portfolio-hr2.svg"
                alt="Asset"
                class="col-4"
              />
              <img
                src="@/assets/img/portfolio-hr3.svg"
                alt="Asset"
                class="col-4"
              />
            </div>
          </div>
        </div>

        <div
          class="
            col-5
            flexCol
            d-flex
            flex-column
            justify-content-between
            rightTop
          "
        >
          <div
            class="
              bg-white
              rounded-lg
              pt-8
              pb-6
              align-items-center
              d-flex
              flex-column
            "
          >
            <h5 class="fw-bold lh-base text-center">Risk Management</h5>
            <img
              src="@/assets/img/portfolio-risk.svg"
              alt="Asset"
              class="mt-3"
            />
          </div>

          <div
            class="
              bg-white
              rounded-lg
              py-8
              align-items-center
              d-flex
              flex-column
              px-12
            "
          >
            <h5 class="fw-bold text-center lh-base">Cashless Payment</h5>
            <img
              src="@/assets/img/portfolio-cashless.svg"
              alt="Asset"
              class="mt-3"
            />
          </div>

          <div class="bg-white rounded-lg py-8 d-flex flex-column px-10">
            <div class="align-items-center">
              <h5 class="fw-bold lh-base text-center mb-6">
                Learning <br />Management System
              </h5>
              <img
                src="@/assets/img/portfolio-lms.svg"
                alt="Asset"
                class="mt-3"
              />
            </div>
            <div class="align-items-left d-flex align-items-center">
              <img
                src="@/assets/img/portfolio-lms1.svg"
                alt="Asset"
                class="mt-3 imgresize"
              />
              <div>
                <p class="fs-6 mb-0" :style="{ fontSize: '18 px' }">Access</p>
                <p class="fw-bold fs-5">Online Course<br />from Mobile</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          bg-white
          rounded-lg
          text-center
          py-4
          px-6
          my-3
          align-items-center
          d-flex
          flex-column
        "
      >
        <h5 class="fw-bold lh-base">Crypto Marketplace</h5>
        <img src="@/assets/img/portfolio-crypto.svg" alt="Asset" />
      </div>

      <div class="row flexRow flexRowBawah">
        <div class="col-5 flexCol d-flex justify-content-between flex-column">
          <div
            class="
              bg-white
              rounded-lg
              text-center
              py-8
              px-6
              mb-1
              align-items-center
              d-flex
              flex-column
            "
          >
            <h5 class="fw-bold lh-base">Strategic Dashboard</h5>
            <img
              src="@/assets/img/portfolio-strategic.svg"
              alt="Asset"
              class="mt-4"
            />
          </div>
          <div
            class="
              bg-white
              rounded-lg
              text-center
              pt-8
              pb-4
              px-2
              my-3
              align-items-center
              d-flex
              flex-column
            "
          >
            <h5 class="fw-bold lh-base mb-2">Healthcare Apps</h5>
            <div class="row align-items-center">
              <img
                src="@/assets/img/portfolio-health1.svg"
                alt="Asset"
                class="col-4 imgUp"
              />
              <img
                src="@/assets/img/portfolio-health2.svg"
                alt="Asset"
                class="col-4 h-75 imgUp"
              />
              <img
                src="@/assets/img/portfolio-health3.svg"
                alt="Asset"
                class="col-4 imgUp"
              />
            </div>
          </div>
        </div>

        <div class="col-7 flexCol d-flex justify-content-between flex-column">
          <div
            class="
              bg-white
              rounded-lg
              text-center
              py-8
              px-6
              mb-1
              align-items-center
              d-flex
              flex-column
            "
          >
            <h5 class="fw-bold lh-base">Muslim Apps Umra & Hajj</h5>
            <div class="row pt-4">
              <img
                src="@/assets/img/portfolio-muslim1.svg"
                alt="Asset"
                class="col-6"
              />
              <img
                src="@/assets/img/portfolio-muslim2.svg"
                alt="Asset"
                class="col-6"
              />
            </div>
          </div>
          <div
            class="
              bg-white
              rounded-lg
              text-center
              pb-1
              pt-4
              px-6
              my-3
              align-items-center
              d-flex
              flex
              layoutUbah
            "
            id="layoutUbah"
          >
            <h5 class="fw-bold lh-base ml-4 stTulis">
              IoT through<br />
              Mobile Apps
            </h5>
            <div class="d-flex">
              <img
                src="@/assets/img/portfolio-iot1.svg"
                alt="Asset"
                class="mt-4"
              />
              <img
                src="@/assets/img/portfolio-iot2.svg"
                alt="Asset"
                class="mt-4"
              />
            </div>
            <h5 class="fw-bold lh-base ml-4 duTulis">
              IoT through<br />
              Mobile Apps
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back.vue";
export default {
  components: { Back },
};
</script>

<style>
@media only screen and (max-width: 480px) {
  #layoutUbah{
    display: flex;
    flex-direction: column-reverse !important;
  }
}

@media only screen and (max-width: 700px) {
  .flexRowAtas {
    display: flex !important;
    flex-direction: column;
  }
  .flexRowBawah {
    display: flex !important;
    flex-direction: column;
  }

  .layoutUbah {
    display: flex;
    flex-direction: row !important;
  }
  .duTulis {
    display: inline !important;
  }
  .stTulis{
    display: none !important;
  }
}

@media only screen and (max-width: 980px) {
  /*Big smartphones [426px -> 600px]*/
  .layoutUbah {
    display: flex;
    flex-direction: column;
  }
  .stTulis {
    display: inline;
  }
  .duTulis {
    display: none;
  }
  .containerKu {
    padding-left: 24px;
    padding-right: 24px;
    width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rightTop {
    gap: 10px;
  }
  .imgresize {
    max-height: 60% !important;
  }
  .flexRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .flexRowAtas {
    display: grid;
    grid-template-columns: 5fr 3fr;
  }
  .flexCol {
    width: 100%;
  }
}

@media only screen and (min-width: 980px) {
  .stTulis {
    display: none;
  }
  .containerKu {
    padding-left: 48px;
    padding-right: 48px;
  }
}
</style>

<template>
  <div>
    <div class="container">
      <div class="back my-3">
        <div
          class="flex align-items-center space-x-1 text-primary cursor-pointer"
          @click="$store.dispatch('back')"
        >
          <b-icon icon="arrow-left">Back</b-icon><span>Home</span>
        </div>
      </div>
      <h1 class="mt-3 mb-3 fw-bold display-5">Support</h1>
      <p class="bt-txt-desc-container mb-4">
        Do you have questions or trouble about our services? <br />
        We are ready to help you find solutions from various types of assistance
        related to our services.
      </p>
      <img src="@/assets/img/supportNew.svg" alt="" class="mb-5" />

      <div class="text-lg">
        <p class="bt-txt-desc-container">Please login first to submit your Trouble Ticket.</p>
        <form action="" method="">
          <div class="row">
            <div class="col-md-6 mb-3">
              <input type="text" class="form-control" placeholder="Username" />
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password"
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn bg-orange text-white fw-bold mb-5 mt-3"
            style="width: 140px"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style>
</style>
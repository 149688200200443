<template>
  <div>
    <div class="container-fluid bg-white">
      <div class="container judul py-4">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <p class="text-primary mt-5 p">Payroll Management</p>
              <h2 class="mt-3 mb-3 fw-bold text-5xl leading-normal">
                Making payroll simpler for employees everywhere
              </h2>
              <h5 class="mb-5 text-xl leading-normal">
                Our integrated system will simplify your payroll processing and eliminate the risk of miscalculation.
              </h5>
              <GetStarted />
            </div>
            <div class="col-md-5 d-flex align-items-center">
              <img src="@/assets/img/payroll.svg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <!-- Companies -->
      <div class="container-fluid" style="background-color: whitesmoke">
        <div class="mt-3">
          <div class="container py-5">
            <div class="d-flex flex-column text-center">
              <p class="text-primary">
                Indonesian leading companies trust birutekno
              </p>
              <div class="d-flex justify-content-center my-2">
                <form
                  action=""
                  class="
                    flex
                    space-x-14
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="">
                    <img src="@/assets/img/logo_dtpeduli.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_jualaja.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_ytruck.svg" alt="" />
                  </div>
                  <div class="">
                    <img src="@/assets/img/logo_cca.svg" alt="" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- AKHIR Companies -->
    </div>
    <!-- GOALS -->
    <div class="container-fluid bg-white">
      <div class="container">
        <p class="text-primary py-5">Achieve Your Goals</p>
        <div class="">
          <div class="row">
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/goals1.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Time savior
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                You don't have to manually enter the information 
                for every cycle. We handle all of it for you.
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img src="@/assets/img/goals2.svg" alt="img" class="" />    
              </div>

              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Turn data into insights
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Good data is essential to understand employee behaviour. 
                With our payroll software you can generate accurate payroll 
                reports like monthly payslips, night shift, overtime, leave report, and more!
              </p>
            </div>
            <div class="col-lg-4 p-4 isi">
              <div class="h-20 flex items-end mb-4">
                <img
                  src="@/assets/img/goals3.svg"
                  alt="img"
                  class="align-bottom"
                />
              </div>
              <div class="h-20">
                <h5 class="fw-bold text-xl mb-3 w-8/12">
                  Transparent pricing
                </h5>
              </div>
              <p class="leading-loose w-11/12">
                Employees want transparency. Let the payroll software work in your favor. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR GOALS -->
    <!-- Conditions -->
    <div class="container py-4 my-5">
      <div class="container">
        <div class="row">
        <div class="text-center">
          <div class="col mt-3">
                <h1 class="bt-txt-title-container font-bold mb-5">
                  Meet the needs of your <br/> employees and your business
                </h1>
            <p
              class="bt-txt-desc-container mb-3"
            >
             Our easy-to-use payroll software helps you accurately pay your employees, <br/>
             this is the only all-in-one platform you will ever need to handle payrolls. 
             No <br/> more dealing with complex software.
            </p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- AKHIR Conditions -->
    <!-- Serve -->
    <div class="bg-white py-5">
      <div class="container">
        <div class="text-center">
          <p class="text-primary">How We Serve You</p>
          <h1 class="font-bold mb-5">
            Simple, Straight-forward and <br/> All-inclusive
          </h1>
        </div>
        <div class="container" id="serve">
          <div v-for="(data, i) in serve" :key="i">
            <div class="row justify-content-center my-">
              <div class="col-md-3 my-4">
                <Img :img="data.img" />
              </div>
              <div class="col-md-6 align-self-center">
                <p class="text-xl fw-bold">{{ data.title }}</p>
                <p class="w-9/12">{{ data.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LetsTalk />
  </div>
  <!-- AKHIR SERVE -->
</template>
<script>
import GetStarted from "@/components/GetStarted";
import Img from "@/components/Img";
import LetsTalk from "@/components/LetsTalk";
export default {
  components: {
    LetsTalk,
    GetStarted,
    Img,
  },
  mounted() {
    this.hrTop();
    this.hrBot();
  },
  methods: {
    hrTop() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.prepend(hr);
    },
    hrBot() {
      let hr = document.createElement("hr");
      let el = document.getElementById("serve").children[1];
      el.append(hr);
    },
  },
  data() {
    return {
      serve: [
        {
          img: "goals1.png",
          title: "Seamless user experience",
          subtitle:
            "With a true single database, information is entered once. ",
        },
        {
          img: "goals2.png",
          title: "Pay your team with just one click",
          subtitle:
            "Easy to use and secure, we allows you to see all your employee’s statistics at a glance to make your payroll management efficient.",
        },
        {
          img: "goals3.png",
          title: "Manage all your payments in one place",
          subtitle:
            "Keep your payments organised with one place to pay employees, plus make amendments, generate reports and more.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.p{
  text-align :left;
} 
@media only screen and (max-width: 992px) {
  h5,
  p {
    text-align: center;
    margin: 0 auto;
  }
  img {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  img {
    margin: 0;
  }
}
.isi img {
  vertical-align: bottom;
}
#serve img {
  width: 211px;
}
</style>
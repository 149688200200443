<template>
  <div>
    <div class="container-lg">
      <Back />
      <h1 class="my-4 text-3xl fw-bold">Our Profile</h1>
    </div>
    <div class="container-lg d-flex justify-content-center">
      <img src="@/assets/img/ourprofile.png" alt="" class="mb-5" width="100%" />
    </div>
    <div class="container-lg">
      <p class="text-lg bt-txt-desc-container">
        Birutekno it's an acronym of Bisnis Rekayasa Unggulan Teknologi or
        Engineering Business Technology. Based in Bandung Indonesia.
      </p>
      <p class="text-lg bt-txt-desc-container">
        We are custom software developer, build your apps from scratch.
        Integrating your software with any devices and any platforms, technology
        and IoT (Internet of Things) support.
      </p>
      <p class="m-0 text-lg bt-txt-desc-container">
        Birutekno Inc. is not just a consultant, it is your solutions.
      </p>
      <div class="w-100 d-flex">
        <router-link :to="{name: 'CompanyProfile'}" class="mx-auto">
          <button
            type="button"
            class="bg-orange text-white my-5 py-3 rounded-2xl w-56"
          >
            Company Profile
          </button>
        </router-link>
      </div>
    </div>
    <div class="bg-founded">
      <div class="container">
        <div class="row justify-between py-5">
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="d-flex align-items-end ms-md-5 ps-5 ms-lg-0 ps-md-0">
              <h1 class="text-primary font-weight-700 text-5xl lg:text-7xl">
                2014
              </h1>
            </div>
            <p class="text-xl flex ms-md-5 ps-5 ms-lg-0 ps-md-0">Founded</p>
          </div>
          <div class="col-md-4 mb-4 mb-md-0 border-start border-end">
            <div class="d-flex align-items-end ms-lg-5 ps-5">
              <h1 class="text-primary font-weight-700 text-5xl lg:text-7xl">
                {{ year }}
              </h1>
              <span class="text-muted pb-3"> years</span>
            </div>
            <p class="text-xl flex ms-lg-5 ps-5">
              Experience handling <br />
              various projects
            </p>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="d-flex align-items-end ms-lg-5 ps-5">
              <h1 class="text-primary font-weight-700 text-5xl lg:text-7xl">
                99+
              </h1>
              <span class="text-muted pb-3"> Project </span>
            </div>
            <p class="text-xl flex ms-lg-5 ps-5">Handled Project</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-lg">
      <!-- Philosopy -->
      <div class="row py-4">
        <div class="col-md-6 col-xl-7">
          <h1 class="my-4 text-2xl fw-bold">Philoshopy</h1>
          <div class="xl:w-9/12">
            <p class="bt-txt-desc">
              Biru or Blue is a color with several meanings. <br />
              Namely: honesty, calmness, loyalty, reliability, harmony, giving
              an impression of space, and sensitive. Biru represents the
              spacious sky, and the deep ocean. We want to present ourselves as
              a blue meanings in technology.
            </p>
            <p>"Birutekno is the intersection between art and technology"</p>
          </div>
        </div>
        <div class="col-md-6 col-xl-5 align-self-center">
          <div
            class="
              flex flex-col
              md:flex-row
              justify-around
              md:justify-center md:space-x-5
              lg:space-x-10
            "
          >
            <div class="text-center p-0">
              <div class="h-34">
                <img
                  src="@/assets/img/logo_phi_creative.svg"
                  alt=""
                  class="mb-2 mx-auto w-20 xl:w-24"
                />
              </div>
              <p class="">Creativity</p>
            </div>
            <div class="text-center p-0">
              <div class="h-34">
                <img
                  src="@/assets/img/logo_phi_tech.svg"
                  alt=""
                  class="mb-2 mx-auto w-20 xl:w-24"
                />
              </div>
              <p class="">Tecknology</p>
            </div>
            <div class="text-center p-0">
              <div class="h-34">
                <img
                  src="@/assets/img/logo_phi_searchEndless.svg"
                  alt=""
                  class="mb-2 mx-auto w-20 xl:w-24"
                />
              </div>
              <p class="">Endless Researchers</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Philosopy -->
    </div>
    <!-- vision -->
    <div class="position-relative">
      <div class="bg-vision my-5">
        <div class="container-lg py-5">
          <h1 class="my-2 fw-bold text-2xl">Vision</h1>
          <p>
            "Serve and facilitate a whole people in the world with DIGITAL
            solution"
          </p>
        </div>
      </div>
    </div>
    <!-- vision -->
    <div class="container-lg">
      <div class="row py-4">
        <div class="col-md-6 mb-3">
          <h2 class="text-xl fw-bold">Autonomy & collaboration</h2>
          <div class="md:w-9/12">
            <p class="bt-txt-desc">
              Being a small team we need every team member to act like a leader
              in their area. We push each of us to stay proactive and drive
              projects forward. While working independently each of us
              collaborates and supports other. Hard work focusedon results.
            </p>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <h2 class="text-xl fw-bold">Openness</h2>
          <div class="md:w-9/12">
            <p class="bt-txt-desc">
              No matter how many projects we have delivered, we always have so
              many things to learn, so it’s important to remember it and keep
              your ego small. We keep ourselves opened to critique, positive and
              optimistic.
            </p>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <h2 class="text-xl fw-bold">Ownership</h2>
          <div class="md:w-9/12">
            <p class="bt-txt-desc">
              The design is done when a project is live and meets its goals or
              solves the addressed problem. To get there we step out from our
              design area and collaborate with other teams to make sure the
              project is launched in the best way possible.
            </p>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <h2 class="text-xl fw-bold">Growth</h2>
          <div class="md:w-9/12">
            <p class="bt-txt-desc">
              We expect personal and professional growth from each of us. From
              learning a new tool or taking bigger responsibilities, we help and
              provide support for stretching ourselves and leaving our comfort
              zone.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "../../components/Back.vue";
export default {
  components: { Back },
  data() {
    return {
      year: new Date().getFullYear() - 2014
    }
  }
};
</script>

<style>
/* .bg-vision {
  background: url("../.././assets/img/vision.png");
  position: absolute;
  z-index: -2;
} */
.bg-vision::before {
  content: "";
  background-image: url("../.././assets/img/vision.png");
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  /* background-size: cover;
  right: 0;
  top: 0;
  position: absolute;
  z-index: -2; */
}
.bg-vision::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0)
  );
  z-index: -1;
}
.bg-founded {
  background-color: #f5f5f5;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-600 {
  font-weight: 600;
  font-size: 40px;
}
.font-weight-500 {
  font-weight: 500;
  font-size: 34px;
}
.fw-500 {
  font-weight: 500;
}
@media only screen and (min-width: 768px) {
}
</style>
